import Button from './Button';
import { toast } from 'react-toastify';

const HowToUseSection = () => {
  const handleMoreInfoClick = () => {
    toast.success(
      <div className="flex flex-col gap-2">
        <div className="text-sm">
          <strong className="font-bold">¡Gracias por tu interés!</strong>
          <p className="text-left">
            Estamos trabajando arduamente para mejorar nuestros servicios.
            ¡Mantente atento a futuras actualizaciones!
          </p>
        </div>
      </div>,
      {
        position: 'top-right',
        autoClose: 10000, // Reduced the duration to make it more user-friendly
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: false,
        toastId: 'info-toast',
      }
    );
  };

  return (
    <div className="px-4 md:border-t md:pt-6 md:border-copper">
      <div
        className="grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-4
      "
      >
        <div
          style={{
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(/monkey.png)`,
          }}
          className="w-full h-44 rounded-2xl flex flex-col justify-center text-white p-4"
        >
          <h3 className="how-to-use-section__step-te">
            <strong>Como usar</strong> Tikipal
          </h3>
          <p className="w-36 text-sm pt-2">
            Compra en cualquier lugar{' '}
            <strong>las entradas para la fiesta</strong>
          </p>
          <div className="w-36 mt-4">
            <Button onClick={handleMoreInfoClick} size="small">
              Más información
            </Button>
          </div>
        </div>
        <div
          style={{
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(/monkey2.png)`,
          }}
          className="w-full h-44 rounded-2xl flex flex-col justify-center text-white p-4"
        >
          <h3 className="how-to-use-section__step-te">
            <strong>Organiza</strong> tu evento
          </h3>
          <p className="w-36 text-sm pt-2">
            Gestiona cumpleaños <strong>eventos o conciertos</strong>
          </p>
          <div className="w-36 mt-4">
            <Button onClick={handleMoreInfoClick} size="small">
              Crear Evento
            </Button>
          </div>
        </div>
        <div
          style={{
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(/monkey3.png)`,
          }}
          className="w-full h-44 rounded-2xl flex flex-col justify-center text-white p-4"
        >
          <h3 className="how-to-use-section__step-te">
            <strong>Tiki</strong>aliados
          </h3>
          <p className="w-36 text-sm pt-2">
            Gestiona tus entradas <strong>fideliza tus clientes</strong>
          </p>
          <div className="w-36 mt-4">
            <Button onClick={handleMoreInfoClick} size="small">
              Registrar Aliado
            </Button>
          </div>
        </div>
      </div>
      {/* TODO: Remove this ? Three steps section */}
      {/* <div className="flex items-center gap-1 h-16">
        <div className="flex justify-center items-center basis-16 flex-shrink-0">
          <Image
            // src="/magnifying-glass.svg"
            src="/address-card.svg"
            alt="id-card"
            width={30}
            height={30}
          />
        </div>
        <div className="flex flex-col justify-center">
          <h3 className="font-bold">Crea tu perfil</h3>
          <Link className="text-[#7f7f7f] underline text-sm" href={'/login'}>
            Regístrate aquí
          </Link>
        </div>
      </div>
      <div className="flex items-center gap-1 h-16">
        <div className="flex justify-center items-center basis-16 flex-shrink-0">
          <Image
            src="/magnifying-glass.svg"
            alt="search"
            width={30}
            height={30}
          />
        </div>
        <div className="flex flex-col justify-center">
          <h3 className="font-bold">Elige tu evento</h3>
          <p className="text-[#7f7f7f] text-sm leading-4">
            Busca tu evento favorito, compra tus entradas y reserva tu espacio
          </p>
        </div>
      </div>
      <div className="flex items-center gap-1 h-16">
        <div className="flex justify-center items-center basis-16 flex-shrink-0">
          <Image src="/qrcode.svg" alt="qrcode" width={30} height={30} />
        </div>
        <div className="flex flex-col justify-center">
          <h3 className="font-bold">Muestra tu código QR en la entrada</h3>
          <p className="text-[#7f7f7f] text-sm">Disfruta de tu evento</p>
        </div>
      </div> */}
    </div>
  );
};

export default HowToUseSection;
