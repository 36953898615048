import React, { useCallback, useState, Suspense } from 'react';
import Image from 'next/image';
// import CardsCarousel from '../components/CardsCarousel';
import CirclesCarousel from '../components/CirclesCarousel';
import { useRouter } from 'next/router';
import SearchModal from '../components/SearchModal';
import Footer from '../components/Footer';
import BannerImage from '../public/banner.png';
import { getOrganizationJsonLd } from '../utils/seo/schema.org';
import Head from 'next/head';
import { GetServerSidePropsContext } from 'next';
import { getSpaces } from './api/spaces';
import { NextSeo } from 'next-seo';
import HowToUseSection from '@/components/HowToUseSection';
import { getCategories } from './api/categories';
import classNames from 'classnames';
import { PiMagnifyingGlass } from 'react-icons/pi';

const CardsCarousel = React.lazy(() => import('../components/CardsCarousel'));
const DiscoverCarousel = React.lazy(
  () => import('../components/DiscoverCarousel')
);

type SearchItem = {
  slug: string;
  name: string;
};

type HomeProps = {
  searchItems: SearchItem[];
  spaces: {
    results: Array<SpaceType & { justImage?: boolean }>;
    count: number;
  };
  newSpaces: {
    results: Array<SpaceType & { justImage?: boolean }>;
    count: number;
  };
  galeriasSpaces: {
    results: Array<SpaceType & { justImage?: boolean }>;
    count: number;
  };
  cl85Spaces: {
    results: Array<SpaceType & { justImage?: boolean }>;
    count: number;
  };
  modeliaSpaces: {
    results: Array<SpaceType & { justImage?: boolean }>;
    count: number;
  };
  primeroDeMayoSpaces: {
    results: Array<SpaceType & { justImage?: boolean }>;
    count: number;
  };
  customEventsSpaces: {
    results: Array<SpaceType & { justImage?: boolean }>;
    count: number;
  };
  chapinerospaces: {
    results: Array<SpaceType & { justImage?: boolean }>;
    count: number;
  };
  categories: SpaceCategory[];
};

const CardsCarouselFallBack = ({
  title,
  subtitle,
  small,
}: {
  title?: string;
  subtitle?: string;
  small?: boolean;
}) => {
  return (
    <div className="px-5 mt-4">
      <div className="flex flex-col justify-center grow my-3">
        <h2 className="text-left text-2xl font-bold">{title}</h2>
        <p className="text-left md:text-base text-sm">{subtitle}</p>
      </div>
      <div className="flex items-center justify-left gap-6">
        {Array.from({ length: 6 }).map((_, idx) => (
          <div
            key={idx}
            className={classNames(
              'animate-pulse bg-gray-300 rounded-2xl mb-2 shrink-0',
              {
                'w-80 h-40': small,
                'w-56 h-60': !small,
              }
            )}
          />
        ))}
      </div>
    </div>
  );
};

const Home = ({
  searchItems,
  spaces,
  newSpaces,
  cl85Spaces,
  galeriasSpaces,
  modeliaSpaces,
  primeroDeMayoSpaces,
  customEventsSpaces,
  chapinerospaces,
  categories,
}: HomeProps) => {
  const [showSearchModal, setShowSearchModal] = useState(false);
  const router = useRouter();
  const referral = router.query.referral;

  const handleOnSelect = useCallback(
    (item: SearchItem) => {
      // the item selected
      if (referral) {
        router.push(`/${item.slug}?referral=${referral}`);
        return;
      }
      router.push(`/${item.slug}`);
    },
    [referral, router]
  );

  const handleSearchFocus = () => {
    setShowSearchModal(true);
  };

  const handleCloseSearchModal = () => {
    setShowSearchModal(false);
  };

  return (
    <>
      <NextSeo
        title="Tikipal - Descubre las mejores fiestas de la ciudad"
        description="Tu destino definitivo para todas tus fiestas. Descubre una amplia selección de fiestas en los mejores sitios de la ciudad."
        canonical="https://tikipal.com.co/"
        languageAlternates={[
          {
            hrefLang: 'es',
            href: 'https://tikipal.com.co/',
          },
        ]}
        openGraph={{
          url: 'https://tikipal.com.co/',
          title: 'Tikipal - Descubre las mejores fiestas de la ciudad',
          description:
            'Tu destino definitivo para todas tus fiestas. Descubre una amplia selección de fiestas en los mejores sitios de la ciudad.',
          type: 'website',
          images: [
            {
              url: 'https://tikipal.com.co/thumbnail.jpeg',
              width: 200,
              height: 200,
              alt: 'Tikipal',
            },
          ],
          site_name: 'Tikipal',
        }}
      />
      <div>
        <Head>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={getOrganizationJsonLd()}
            key="product-jsonld"
          />
        </Head>
        <div className="flex flex-col relative overflow-x-hidden">
          <div className="relative flex flex-col justify-center items-center h-[15rem] md:h-[20rem] lg:h-[25rem] border-0 text-center rounded-b-2xl overflow-hidden">
            <Image
              className="absolute z-0 object-cover"
              src={BannerImage}
              alt="banner"
              loading="lazy"
              quality={50}
              sizes="(max-width: 768px) 100vw,
            (max-width: 1200px) 50vw,
            33vw"
              fill
            />
            <div className="w-full h-full bg-[#000419] opacity-20 absolute top-0 left-0" />
            <div className="pt-6 lg:pt-0 z-10 flex flex-col justify-center items-center h-[14rem] md:h-[18rem] lg:h-[20rem]">
              <div className="flex flex-col items-center text-center z-10">
                <h2 className="text-white font-bold text-2xl md:text-3xl px-4">
                  ¡Encuentra los mejores
                </h2>
                <h3 className="text-white md:text-3xl text-2xl px-10 pt-0">
                  lugares de Bogotá!
                </h3>
              </div>
              <div className="md:w-[32rem] w-[22rem] pt-[2rem] max-w-lg h-[1rem]">
                <div className="relative w-full">
                  <input
                    type="text"
                    className="border p-2 rounded-md w-full pl-8 z-40 focus:border-[#FC7851] focus:outline-none placeholder:text-[#4B4B4B] placeholder:text-center"
                    placeholder="Busca tu lugar favorito"
                    onFocus={handleSearchFocus}
                  />
                  <span className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[#4B4B4B] pointer-events-none md:translate-x-36 translate-x-[4.5rem]">
                    <PiMagnifyingGlass />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <Suspense
              fallback={<CardsCarouselFallBack title="Descubre Bogotá" small />}
            >
              <DiscoverCarousel categories={categories} />
            </Suspense>
            {!!newSpaces?.results?.length && (
              <Suspense fallback={<CardsCarouselFallBack />}>
                <CardsCarousel
                  spaces={[
                    {
                      profile_image: '/asobares.png',
                      profile_image_thumbnail: '/asobares.png',
                      slug: 'mi-destino-tu-noche',
                      music: [],
                      name: 'Asobares',
                      capacity: 0,
                      id: 'asobares',
                      logo: '/asobares.png',
                      promoter: {
                        id: 'asobares',
                        name: 'Asobares',
                      },
                      space_schedules: [],
                      space_exclusions: [],
                      product_description: '',
                      space_products: [],
                      space_images: [],
                      services: [],
                      description:
                        'Tu destino definitivo para todas tus fiestas.',
                      promoter_id: 'asobares',
                      justImage: true,
                      customUrl: '/category/mi-destino-tu-noche',
                    } as SpaceType & { justImage: boolean },
                  ].concat(newSpaces.results as any)}
                  title="Sitios Nuevos"
                  subtitle="Estás en el momento"
                  category="all"
                />
              </Suspense>
            )}
            {!!cl85Spaces?.results?.length && (
              <Suspense
                fallback={
                  <CardsCarouselFallBack
                    title="Calle 85"
                    subtitle="Los mejores espacios de la zona."
                  />
                }
              >
                <CardsCarousel
                  spaces={cl85Spaces.results}
                  title="Calle 85"
                  subtitle="Los mejores espacios de la zona."
                  showMoreButton={cl85Spaces.count > cl85Spaces.results.length}
                  category="calle-85"
                />
              </Suspense>
            )}
            {!!chapinerospaces?.results?.length && (
              <Suspense
                fallback={
                  <CardsCarouselFallBack
                    title="Chapinero"
                    subtitle="Los mejores espacios de la zona."
                  />
                }
              >
                <CardsCarousel
                  spaces={chapinerospaces.results}
                  title="Chapinero"
                  subtitle="Los mejores espacios de la zona."
                  showMoreButton={
                    chapinerospaces.count > chapinerospaces.results.length
                  }
                  category="zona-chapinero"
                />
              </Suspense>
            )}
            {!!galeriasSpaces?.results?.length && (
              <Suspense
                fallback={
                  <CardsCarouselFallBack
                    title="Galerías"
                    subtitle="Los mejores espacios de la zona."
                  />
                }
              >
                <CardsCarousel
                  spaces={galeriasSpaces.results}
                  title="Galerías"
                  subtitle="Los mejores espacios de la zona."
                  showMoreButton={
                    galeriasSpaces.count > galeriasSpaces.results.length
                  }
                  category="zona-galerias"
                />
              </Suspense>
            )}
            {!!modeliaSpaces?.results?.length && (
              <Suspense
                fallback={
                  <CardsCarouselFallBack
                    title="Modelia"
                    subtitle="Los mejores espacios de la zona."
                  />
                }
              >
                <CardsCarousel
                  spaces={modeliaSpaces.results}
                  title="Modelia"
                  subtitle="Los mejores espacios de la zona."
                  showMoreButton={
                    modeliaSpaces.count > modeliaSpaces.results.length
                  }
                  category="zona-modelia"
                />
              </Suspense>
            )}
            {!!primeroDeMayoSpaces?.results?.length && (
              <Suspense
                fallback={
                  <CardsCarouselFallBack
                    title="1ro de Mayo"
                    subtitle="Los mejores espacios de la zona."
                  />
                }
              >
                <CardsCarousel
                  spaces={primeroDeMayoSpaces.results}
                  title="1ro de Mayo"
                  subtitle="Los mejores espacios de la zona."
                  showMoreButton={
                    primeroDeMayoSpaces.count >
                    primeroDeMayoSpaces.results.length
                  }
                  category="zona-1ro-de-mayo"
                />
              </Suspense>
            )}
            {!!customEventsSpaces?.results?.length && (
              <Suspense
                fallback={
                  <CardsCarouselFallBack
                    title="Eventos"
                    subtitle="Que no te lo cuenten, vívelo."
                  />
                }
              >
                <CardsCarousel
                  spaces={customEventsSpaces.results}
                  title="Eventos"
                  subtitle="Que no te lo cuenten, vívelo."
                  showMoreButton={
                    customEventsSpaces.count > customEventsSpaces.results.length
                  }
                  category="eventos"
                />
              </Suspense>
            )}
            {/* TODO: remover nuestros aliados? */}
            {/* {!!spaces?.results?.length && (
              <Suspense
                fallback={
                  <CardsCarouselFallBack
                    title="Nuestros aliados"
                    subtitle="Lo mejor de la noche capitalina."
                  />
                }
              >
                <CirclesCarousel
                  spaces={spaces.results}
                  title="Nuestros aliados"
                  subtitle="Lo mejor de la noche capitalina."
                  showMoreButton={spaces.count > spaces.results.length}
                />
              </Suspense>
            )} */}
          </div>
        </div>
        <HowToUseSection />
        <Footer />
        <SearchModal show={showSearchModal} onClose={handleCloseSearchModal} />
      </div>
    </>
  );
};

// This value is considered fresh for ten seconds (s-maxage=10).
// If a request is repeated within the next 10 seconds, the previously
// cached value will still be fresh. If the request is repeated before 59 seconds,
// the cached value will be stale but still render (stale-while-revalidate=59).
//
// In the background, a revalidation request will be made to populate the cache
// with a fresh value. If you refresh the page, you will see the new value.
export async function getServerSideProps({
  req,
  res,
}: GetServerSidePropsContext) {
  res.setHeader(
    'Cache-Control',
    'public, s-maxage=10, stale-while-revalidate=59'
  );

  // Todo: pass the search param on the client side
  // const data = await getSearchItemsSpaces({ req });
  const spacesData = await getSpaces(false, undefined, 10);
  const newSpacesData = await getSpaces(true);

  const cl85Spaces = await getSpaces(false, 'calle-85', 8);
  const galeriasSpaces = await getSpaces(false, 'zona-galerias', 8);
  const modeliaSpaces = await getSpaces(false, 'zona-modelia', 8);
  const primeroDeMayoSpaces = await getSpaces(false, '1ro-de-mayo', 8);
  const customEventsSpaces = await getSpaces(false, 'eventos', 8);
  const chapinerospaces = await getSpaces(false, 'zona-chapinero', 8);

  const categories = await getCategories();
  const allSpaces = await getSpaces(false);
  const searchItems = allSpaces?.results.map((space) => ({
    slug: space.slug,
    name: space.name,
  }));

  return {
    props: {
      searchItems,
      spaces: spacesData,
      newSpaces: newSpacesData,
      cl85Spaces: cl85Spaces,
      galeriasSpaces: galeriasSpaces,
      modeliaSpaces: modeliaSpaces,
      primeroDeMayoSpaces: primeroDeMayoSpaces,
      customEventsSpaces: customEventsSpaces,
      chapinerospaces: chapinerospaces,
      categories: categories?.results || [],
    },
  };
}

export default Home;
