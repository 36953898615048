import Modal from "./Modal";
import TermsAndConditionsSection from "./TermsAndConditionsSection";
type TermsAndConditionsModalProps = {
  show: boolean;
  onClose: () => void;
};

const TermsAndConditionsModal = ({
  show,
  onClose,
}: TermsAndConditionsModalProps) => {
  return (
    <>
      <Modal
        show={show}
        onClose={onClose}
        size="large"
        hideCloseButton
        showHeader
      >
        <TermsAndConditionsSection />
      </Modal>
    </>
  );
};

export default TermsAndConditionsModal;
