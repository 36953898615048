import { ReactElement, useLayoutEffect, useState } from 'react';
import classNames from 'classnames';
import TermsAndConditionsModal from './TermsAndConditionsModal';
import { IoMail } from 'react-icons/io5';
import { FiInstagram } from 'react-icons/fi';
import { BsWhatsapp } from 'react-icons/bs';
import { FaFacebook } from 'react-icons/fa';
import Image from 'next/image';
import { useRouter } from 'next/router';

const Footer = (): ReactElement => {
  const [small, setSmall] = useState(false);

  useLayoutEffect(() => {
    if (window.innerWidth < 768) {
      setSmall(true);
    }
  }, []);

  const router = useRouter();
  const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] =
    useState(false);
  return (
    <div className="w-full flex flex-col px-4 bg-[#FE6535] text-white rounded-t-3xl pt-4 mt-6">
      <h2 className="text-xl font-bold  pt-4 w-full">Nosotros</h2>
      <button
        className="text-white text-base text-left underline appearance-none"
        onClick={() => router.push('/login')}
      >
        Registrate como usuario
      </button>
      <h2 className="text-xl font-bold  pt-4 w-full">Legal</h2>
      <button
        className="text-white text-base text-left underline appearance-none"
        onClick={() => setShowTermsAndConditionsModal(true)}
      >
        Términos y Condiciones
      </button>
      <h2 className="text-xl font-bold  pt-4 w-full">Contactanos</h2>
      <div
        className={classNames('flex flex-col items-center pt-2', {
          'pb-20': small,
          'pb-4': !small,
        })}
      >
        <div className="w-full flex justify-start">
          <div className="pt-2 flex justify-center flex-row gap-6">
            <div>
              <a
                href="https://wa.me/573054584137"
                target="_blank"
                rel="noreferrer"
              >
                <BsWhatsapp className="inline-block mr-2 " size={'2rem'} />
              </a>
            </div>
            <div>
              <a
                href="https://www.instagram.com/tikipal.co/"
                target="_blank"
                rel="noreferrer"
              >
                <FiInstagram className="inline-block mr-2 " size={'2rem'} />
              </a>
            </div>
            <div>
              <a
                href="https://www.facebook.com/tikipal.col"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebook className="inline-block mr-2 " size={'2rem'} />
              </a>
            </div>
            <div>
              <a
                href="mailto:support@tikipal.co"
                target="_blank"
                rel="noreferrer"
              >
                <IoMail className="inline-block mr-2 " size={'2rem'} />
              </a>
            </div>
          </div>
        </div>
        <div className="border-t border-white h-1 w-full my-6" />
        <Image
          src="/logo-horizontal.svg"
          alt="tikipal"
          height={40}
          width={200}
          className="h-10 w-40 object-cover"
        />
        <div className="mt-6 border-t bg-white w-full flex justify-between items-center gap-4 py-2 pl-4 rounded">
          <h1
            className={classNames(' font-bold text-copper', {
              'text-center': small,
            })}
          >
            Partners
          </h1>
          <Image
            src="/asobares-logo.svg"
            alt="asobares"
            height={80}
            width={180}
            className="object-cover"
          />
        </div>
        <hr />
      </div>
      <TermsAndConditionsModal
        show={showTermsAndConditionsModal}
        onClose={() => setShowTermsAndConditionsModal(false)}
      />
    </div>
  );
};

export default Footer;
