export const getSpaceJsonLd = (space: SpaceType): { __html: string } => {
  return {
    __html: `{
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": "${space.name} - Tikipal",
    "image": [
     ],
    "description": "${space.description?.replace(/"/g, '\\"')}",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "5",
      "reviewCount": "100"
    },
    "offers": {
      "@type": "Offer",
      "url": "https://tikipal.com.co/${space.slug}",
      "priceCurrency": "COP",
      "priceValidUntil": "N/A",
      "price": "${space.cover_price}",
      "availability": "https://schema.org/InStock",
      "seller": {
        "@type": "Organization",
        "name": "Tikipal"
      }
    }
  }
`,
  };
};

// TODO: use this in /about page
export const getOrganizationJsonLd = (): { __html: string } => {
  return {
    __html: `
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "Tikipal",
      "legalName": "Tikipal SAS",
      "url": "http://www.tikipal.com.co",
      "logo": "https://tikipal.com.co/logo-circle.svg",
      "foundingDate": "2022",
      "founders": [
        {
          "@type": "Person",
          "name": "Camilo Acosta"
        },
        {
          "@type": "Person",
          "name": "Jobelo Quintero"
        },
        {
          "@type": "Person",
          "name": "Carlos Martinez"
        },
        {
          "@type": "Person",
          "name": "Sebastian Llano"
        }
      ],
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "CARRERA 12 140 91",
        "addressRegion": "Bogotá",
        "addressCountry": "Colombia"
      },
      "contactPoint": {
        "@type": "ContactPoint",
        "contactType": "customer support",
        "telephone": "[+57-3054584140]",
        "email": "support@tikipal.co"
      },
      "sameAs": [
        "https://www.instagram.com/tikipal.co/"
      ]
    }
`,
    // TODO: add the same as: linkedin, facebook, twitter, instagram, youtube
  };
};
